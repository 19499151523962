import React from 'react'
import { FormattedMessage } from 'react-intl'

import { MIN_TABLET_SIZE, MIN_DESKTOP_SIZE } from '../utils'
import { makeBtStyles } from '../Theme'
import CollapsableSection from '../components/CollapsableSection'
import CarrierIcon from '../components/CarrierIcon'
import TrackingNumberLink from '../components/TrackingNumberLink'
import SignatureRequiredTag from '../components/SignatureRequiredTag'
import { AbbreviatedDestinationAddress } from '../components/DestinationAddress'

import { CardSection } from './Card'
import ShipmentInformation from './ShipmentInformation'
import CarbonNeutral from '../components/CarbonNeutral'

const useStyles = makeBtStyles(theme => ({
  root: {
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      display: 'none',
    },
  },
  carrierIcon: {
    height: '35px',
    opacity: '0.5',
    filter: 'grayscale(1)',
    width: 'auto !important',
    paddingRight: '8px',
    '&.carrierIconSvg': {
      maxHeight: '14px',
    },
    '&.carrierIconText': {
      fontWeight: '300',
    },
  },
  contentLabel: {
    margin: '10px 0px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '8px',
    display: 'block',
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      margin: '20px 0 5px',
    },
  },
  destinationTrackingSection: {
    paddingLeft: '40px',
    borderBottom: '0.5px solid #DFE3E8 !important',
    borderTop: '0.5px solid #DFE3E8',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
      paddingTop: '23px',
    },
    [theme.breakpoints.down(MIN_DESKTOP_SIZE)]: {
      display: 'block',
      // height: '170px',
    },
    [theme.breakpoints.down(MIN_TABLET_SIZE)]: {
      borderTop: 'none',
      marginTop: '-20px',
    },
  },
  finalDestination: {
    width: '250px',
    fontSize: '14px',
  },
  signatureRequired: {
    fontSize: '9px',
  },
  trackingNumber: {
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '8px',
  },
  trackingNumberSection: {
    maxWidth: '250px',
    // [theme.breakpoints.up(MIN_DESKTOP_SIZE)]: {
    //   marginBottom: '13px',
    // },
  },
  icon: {
    color: theme.colors.main,
    position: 'absolute',
    right: '40px',
    fontSize: '20px',
  },
}))

const useToggleStyles = makeBtStyles(theme => ({
  root: {
    [theme.breakpoints.up(MIN_TABLET_SIZE)]: {
      display: 'none',
    },
  },
  collapseToggle: {
    textDecoration: 'none !important',
    color: 'black',
    fontSize: '14px',
    fontWeight: '500',
    borderTop: '1px solid #DFE3E8',
    width: '100%',
    padding: '20px 30px 20px 40px',
  },
  showMore: {
    display: 'inline-block',
  },
}))

export default function DestinationTrackingSection() {
  const classes = useStyles()
  const toggleClasses = useToggleStyles()

  return (
    <>
      <ShipmentInformation classes={classes} />
      <CollapsableSection
        classes={classes}
        toggleClasses={toggleClasses}
        label={
          <FormattedMessage description="layout02.mobile.collapsableSection" defaultMessage="Shipment Information" />
        }>
        <CardSection className={classes.destinationTrackingSection}>
          <div>
            <label className={classes.contentLabel}>
              <FormattedMessage description="layout02.mainCard.trackingNumberLabel" defaultMessage="Tracking Number" />:
            </label>
            <div className={classes.trackingNumber}>
              <CarrierIcon className={classes.carrierIcon} fallbackToName />
              <TrackingNumberLink />
            </div>
            <SignatureRequiredTag className={classes.signatureRequired} />
          </div>
          <div className={classes.finalDestination}>
            <label className={classes.contentLabel}>
              <FormattedMessage description="layout02.mainCard.destinationLabel" defaultMessage="Final Destination" />
            </label>
            <AbbreviatedDestinationAddress />
          </div>
          <CarbonNeutral />
        </CardSection>
      </CollapsableSection>
    </>
  )
}
