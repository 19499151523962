import React, { useState } from 'react'

import { Carrier } from '../dataProviders'

import { ReactComponent as Usps } from './usps.svg'
import { ReactComponent as GlobalPost } from './global-post.svg'

// @ts-ignore - no typing info for YML files
import carrierData from 'resources/carriers.yml'

import { useShipmentData } from '../dataProviders'

export default function CarrierIcon({
  className = '',
  carrier_code,
  fallbackToName = false,
}: {
  className?: string
  carrier_code?: Carrier
  fallbackToName?: boolean
}) {
  const { carrier_code: contextCarrierCode } = useShipmentData()

  carrier_code = carrier_code || contextCarrierCode

  const [imageUrl, setImageUrl] = useState(`https://logos.shipstation.com/ipaas/carriers/${carrier_code}/logo.svg`)
  const carrierName = carrierData[carrier_code]?.name
  const imageClassName = `${className} carrierIconSvg`

  const onError = () => setImageUrl('')

  if (!carrier_code) {
    return null
  }

  if (carrier_code === 'usps') {
    return <Usps className={imageClassName} />
  }
  // the ipaas registry doesn't currently house a logo for global post, since that integration is a WIP
  else if (carrier_code === Carrier.GlobalPost) {
    return <GlobalPost className={imageClassName} />
  } else if (imageUrl) {
    return <img src={imageUrl} className={imageClassName} alt={carrierName || carrier_code} onError={onError} />
  } else if (fallbackToName && carrierName) {
    return <span className={`${className} carrierIconText`}>{carrierName}:</span>
  } else {
    return null
  }
}
