import React from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { makeBtStyles } from './Theme'
import { layouts } from './useLayout'
import { isDevMode } from './utils'
import { currentLayoutKey, currentThemeKey, currentMockName } from './Mirage'

export let TrackingDevControls = () => null

const KNOWN_MOCKS = [
  // List of mocks in `public/mocks`
  'australiaShipment',
  'canadaShipment',
  'delivered',
  'manyTrackingEvents',
  'carbonNeutral',
  'missingBrandingConfig',
  'missingShipmentData',
  'missingTrackingData',
  'newZealandShipment',
  'testingMock',
  'threeLineItems',
  'ukShipment',
  'unknownCarrier',
  'voided',
  'weightRounding',
  'withLinks',
  'withLinksV1',
]

if (isDevMode()) {
  const reloadWithConfig = config => {
    const d = config.mockName || currentMockName
    const layout_key = config.layout_key || currentLayoutKey
    const theme_key = config.theme_key || currentThemeKey
    window.location = `/?devmode=1&d=${d}&branding.layout_key=${layout_key}&branding.theme_key=${theme_key}`
  }

  const useStyles = makeBtStyles(
    {
      root: {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        textAlign: 'right',
        background: '#eee',
        padding: '4px',
        borderRadius: '3px',
        boxShadow: '1px 1px 10px rgba(0,0,0,0.25)',
      },
    },
    { name: 'dev-controls' },
  )

  TrackingDevControls = ({ data, setData }) => {
    const classes = useStyles()

    const onChangeLayout = layout_key => reloadWithConfig({ layout_key })
    const onChangeTheme = theme_key => reloadWithConfig({ theme_key })
    const onChangeMock = mockName => reloadWithConfig({ mockName })

    window.devMode = {
      ...(window.devMode || {}),
      data,
      setData,
    }

    return (
      <div className={classes.root}>
        <Select value={currentThemeKey} onChange={e => onChangeTheme(e.target.value)}>
          {['light', 'dark', 'paparazzi', 'peach', 'unicorns', 'custom'].map(theme_key => (
            <MenuItem value={theme_key} key={theme_key}>
              {theme_key}
            </MenuItem>
          ))}
        </Select>
        <br />
        <Select value={currentLayoutKey} onChange={e => onChangeLayout(e.target.value)}>
          {Object.keys(layouts).map(layout_key => (
            <MenuItem value={layout_key} key={layout_key}>
              {layout_key}
            </MenuItem>
          ))}
        </Select>
        <br />
        <Select value={currentMockName} onChange={e => onChangeMock(e.target.value)}>
          {KNOWN_MOCKS.map(mockName => (
            <MenuItem value={mockName} key={mockName}>
              {mockName}
            </MenuItem>
          ))}
        </Select>
      </div>
    )
  }
}
