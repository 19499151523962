import React from 'react'
import { FormattedMessage } from 'react-intl'

import TrackingNumberLink from '../components/TrackingNumberLink'
import { AbbreviatedDestinationAddress } from '../components/DestinationAddress'
import CarrierIcon from '../components/CarrierIcon'
import SignatureRequiredTag from '../components/SignatureRequiredTag'

import { CardSection } from './Card'
import CarbonNeutral from '../components/CarbonNeutral'

interface InfoClasses {
  root?: string
  destinationTrackingSection?: string
  finalDestination?: string
  contentLabel?: string
  trackingNumberSection?: string
  trackingNumber?: string
  carrierIcon?: string
  signatureRequired?: string
  carbonNeutral?: string
}

export default function ShipmentInformation({ classes }: { classes: InfoClasses }) {
  return (
    <div className={classes.root}>
      <CardSection className={classes.destinationTrackingSection}>
        <div className={classes.finalDestination}>
          <label className={classes.contentLabel}>
            <FormattedMessage description="layout02.mainCard.destinationLabel" defaultMessage="Final Destination" />
          </label>
          <AbbreviatedDestinationAddress />
        </div>
        <div className={classes.trackingNumberSection}>
          <label className={classes.contentLabel}>
            <FormattedMessage description="layout02.mainCard.trackingNumberLabel" defaultMessage="Tracking Number" />:
          </label>
          <div className={classes.trackingNumber}>
            <CarrierIcon className={classes.carrierIcon} fallbackToName />
            <TrackingNumberLink />
          </div>
            <SignatureRequiredTag className={classes.signatureRequired} />
        </div>
        <CarbonNeutral />
      </CardSection>
    </div>
  )
}
