import React from 'react'
import {
  faFacebookF,
  faLinkedinIn,
  faPinterest,
  faInstagram,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'

import Icon from '../components/Icon'
import { SocialMediaLink as Link } from '../components/BtLink'
import { SocialLink } from '../dataProviders'

const socialMediaInformation = {
  facebook: faFacebookF,
  linkedin: faLinkedinIn,
  pinterest: faPinterest,
  instagram: faInstagram,
  twitter: faTwitter,
  youtube: faYoutube,
}

export default function SocialMediaLink({
  link,
  classes = {},
}: {
  link: SocialLink
  classes: {
    root?: string
    icon?: string
  }
}) {
  const { type, url } = link
  const icon = socialMediaInformation[type]

  return (
    <Link key={type} href={url} target="_blank" className={`${classes.root} social-type-${type}`} aria-label={type}>
      <Icon icon={icon} className={classes.icon} />
    </Link>
  )
}
